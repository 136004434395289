import { AppPlugin } from '@grafana/data';
import { SimpleOptions } from 'types';
// import { GeosteeringPanelOptions } from './types';
// import { SimplePanel } from './SimplePanel';
// import { Instances } from './pages/Instances';

export const plugin = new AppPlugin<SimpleOptions>();

// .addConfigPage({
//   title: 'Defaults',
//   icon: 'fa fa-info',
//   body: DefaultsConfigPage,
//   id: 'defaults',
// });
